let user = "", token = "";
if (localStorage.getItem("currentUser")) {
    let auth: any = localStorage.getItem("currentUser"),
        pAuth = JSON.parse(auth)
    user = user;
    token = pAuth.auth_token;
}

export const initialState = {
    userDetails: user,
    token: token,
    loading: false,
    errorMessage: null
};

export const AuthReducer = (initialState: any, action: any) => {
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {
                ...initialState,
                loading: true
            };
        case "LOGIN_SUCCESS":
            return {
                ...initialState,
                user: action.payload.user,
                token: action.payload.auth_token,
                loading: false
            };
        case "LOGOUT":
            return {
                ...initialState,
                user: "",
                token: ""
            };

        case "LOGIN_ERROR":
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

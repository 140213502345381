import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Routes from "./Routes";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';

import { AuthProvider, useAuthState } from './context';

library.add(fab, faCheckSquare, faCoffee)

function App() {
  const auth = useAuthState();

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_URL}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    const token = auth.token;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;

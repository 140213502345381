export function LoginUser(dispatch: any, loginPayload: any) {
    dispatch({ type: 'LOGIN_SUCCESS', payload: loginPayload });
    localStorage.setItem('currentUser', JSON.stringify(loginPayload));
    // localStorage.setItem('token', loginPayload.auth_token);
    // localStorage.setItem('userId', loginPayload.userId);
}

export async function logout(dispatch: any) {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('currentUser');
    // localStorage.removeItem('token');
    // localStorage.removeItem('userId');
}

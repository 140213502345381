import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from "react-router-dom";
import Layout from "./components/layout";
import { Component } from 'react';
import { useAuthState } from './context';

const LoginPage = React.lazy(() => import("./pages/login"));
const HomePage = React.lazy(() => import("./pages/home"));
const AdminWrapper = React.lazy(() => import("./pages/admin"));
const ServicesPage = React.lazy(() => import("./pages/services"));
const TechnologiesPage = React.lazy(() => import("./pages/technologies"));
const JobAspirantsPage = React.lazy(() => import("./pages/job-aspirants"));
const GeographiesPage = React.lazy(() => import("./pages/geographies"));
const InfoPage = React.lazy(() => import("./pages/info"));

const AllRoutes = [
     {
          path: '/admin',
          component: AdminWrapper,
          isPrivate: true
     },
     // {
     //      path: '/logout',
     //      component: LogOut
     // },
     {
          path: '/home',
          component: HomePage,
          isPrivate: false
     },
     {
          path: '/services',
          component: ServicesPage,
          isPrivate: false
     },
     {
          path: '/technologies',
          component: TechnologiesPage,
          isPrivate: false
     },
     {
          path: '/job-aspirants',
          component: JobAspirantsPage,
          isPrivate: false
     },
     {
          path: '/georgaphies',
          component: GeographiesPage,
          isPrivate: false
     },
     {
          path: '/info',
          component: InfoPage,
          isPrivate: false
     },
     {
          path: '/login',
          component: LoginPage,
          isPrivate: false
     }
];

const AppRoutes = ({ component: Component, path, isPrivate, ...rest }: any) => {
     const userDetails = useAuthState()
     return (
          <Route
               {...rest}
               path={path}
               render={routeProps =>
                    isPrivate && !Boolean(userDetails.token) ? (
                         <Redirect
                              to={{ pathname: "/login" }}
                         />
                    ) : (
                         <Component {...routeProps} />
                    )
               }

          />
     )
}

export default function Routes() {
     // const [token, setToken] = useState('');      //useState(auth.details?.token);
     const auth = useAuthState();
     // function NoMatch() {
     //      const location = useLocation();

     //      return (
     //           <div>
     //                <h3>Error 404</h3>
     //                <p>
     //                     No match for <code>{location.pathname}</code>
     //                </p>
     //           </div>
     //      );
     // }

     // function LogOut() {
     //      // auth.logout();
     //      // setToken(null);
     //      return (
     //           <Redirect to="/home" push />
     //      )
     // }

     return (
          <>
               <Router>
                    <Layout token={auth.token}>
                         <Suspense fallback={<code>Loading...</code>}>
                              <Switch>
                                   {AllRoutes.map((route) => (
                                        <AppRoutes
                                             key={route.path}
                                             path={route.path}
                                             component={route.component}
                                             isPrivate={route.isPrivate}
                                        />
                                   ))}
                                   <Redirect exact from="/" to="/home" />
                              </Switch>
                              {/* {token ? (
                                   <Switch>
                                        <Redirect exact from="/" to="/admin" />
                                        <Redirect exact from="/login" to="/admin" />
                                        <Route path="/admin" component={AdminWrapper} />
                                        <Route path="/logout" component={LogOut} />
                                   </Switch>
                              ) : (
                                   <Switch>
                                        <Redirect exact from="/" to="/home" />
                                        <Route path="/home" component={HomePage} />
                                        <Route path="/services" component={ServicesPage} />
                                        <Route path="/technologies" component={TechnologiesPage} />
                                        <Route path="/job-aspirants" component={JobAspirantsPage} />
                                        <Route path="/georgaphies" component={GeographiesPage} />
                                        <Route path="/info" component={InfoPage} />

                                        <Route path="/login" component={LoginPage} />

                                        <Route path="*" component={NoMatch} />
                                   </Switch>
                              )} */}
                         </Suspense>
                    </Layout>
               </Router>
          </>
     );
}

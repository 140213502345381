import { NavLink } from "react-router-dom";
import { Container, DropdownButton, Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";
// import authContext from "../../context/auth-context";
import { useAuthState, useAuthDispatch, logout } from '../../context';
import Button from 'react-bootstrap/Button';
// export function AuthenticatedNav() {
//   // auth.
//   return (
//     <Navbar bg="light" className="shadow-sm" expand="lg" fixed="top">
//       <Container fluid>
//         <Navbar.Brand href="/" className="text-white">
//           <img className="d-inline-block align-top" width="250" src="/YTR-infotech.svg" alt="YTR Infotech" />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="navbar" />
//         <Navbar.Collapse className="justify-content-end" id="navbar">
//           <Nav className="d-lg-none">
//             <NavLink className="nav-link" to="/home">Home</NavLink>
//             <NavLink className="nav-link" to="/chats">Chats</NavLink>
//             <NavLink className="nav-link" to="/clients">Clients</NavLink>
//             <NavLink className="nav-link" to="/packages">Packages</NavLink>
//             <NavLink className="nav-link" to="/profile">Profile</NavLink>
//             <NavLink className="nav-link" to="/resources">Resources</NavLink>
//             <NavLink className="nav-link" to="/schedule">Schedule</NavLink>
//             <NavLink className="nav-link" to="/settings">Settings</NavLink>
//             <Nav.Link>Logout</Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//         <Nav.Item className="d-none d-lg-block">
//           <DropdownButton variant="dark" title={<i className="fas fa-bell"></i>}>
//             <Dropdown.Header>Notifications</Dropdown.Header>
//             <Dropdown.Divider />
//             <Dropdown.Item>PT #18 has been created</Dropdown.Item>
//             <Dropdown.Divider />
//             <Dropdown.Item as="small">More Notifications</Dropdown.Item>
//           </DropdownButton>
//         </Nav.Item>
//         <Nav.Item className="d-none d-lg-block">
//           <DropdownButton variant="dark" title={<i className="fas fa-th"></i>}>
//             <Dropdown.Header>Other Apps</Dropdown.Header>
//           </DropdownButton>
//         </Nav.Item>
//         <Nav.Item className="d-none d-lg-block mr-5 pr-5">
//           <DropdownButton
//             variant="dark"
//             title={<img src="/assets/avatar-1.jpg" height="42" className="rounded-circle" alt="avatar" />}
//           >
//             <NavLink to="/profile" className="dropdown-item">Profile</NavLink>
//             <Dropdown.Item onClick={() => auth.logout()}>Sign Out</Dropdown.Item>
//           </DropdownButton>
//         </Nav.Item>
//       </Container>
//     </Navbar>
//   );
// }

export function UnauthenticatedNav() {
  // const auth = useContext(authContext);
  const token = '';   //auth.details?.token;
  const auth = useAuthState();
  const dispatch = useAuthDispatch();

  function Logout() {
    logout(dispatch)
  }

  return (
    <Navbar bg="light" className="shadow-sm" expand="lg">
      <Container fluid>
        <Navbar.Brand href="/" className="text-muted">
          <img className="d-inline-block align-top" width="250" src="/YTR-infotech.svg" alt="YTR Infotech" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Collapse className="justify-content-end" id="navbar">
          <Nav>
            <NavLink className="nav-link" to="/home">Home</NavLink>
            {/* <NavLink className="nav-link" to="/services">Services</NavLink> */}
            <NavDropdown title="Services">
              {/* Services list */}
              <NavDropdown.Item href="#action3">Recruitment & Procurement</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Research & Development</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Application Support</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Infrastructure Support</NavDropdown.Item>
              {/* Services list end */}
            </NavDropdown>
            <NavDropdown title="Technologies">
              {/* Technologies list */}
              <NavDropdown.Item href="#action3">Cloud Solutions</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Data Mining</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Artificial Intelligence</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Data Analytics</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Intelligent Automation</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Digital Transformation</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Networking Solutions</NavDropdown.Item>
              <NavDropdown.Item href="#action4"></NavDropdown.Item>
              {/* Technologies list end */}
            </NavDropdown>
            <NavDropdown title="Careers">
              <NavDropdown.Item href="#action3">Global Job Openings</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Training</NavDropdown.Item>
            </NavDropdown>
            {/* <NavLink className="nav-link" to="/industries">Industries</NavLink> */}
            <NavLink className="nav-link" to="/employees">Employees</NavLink>
            {auth.token &&
              <Button variant="primary" type="button" onClick={() => Logout()}>Logout</Button>
            }
            {!auth.token &&
              <NavLink className="nav-link" to="/login">Login</NavLink>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

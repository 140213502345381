import { useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { UnauthenticatedNav } from "./top";
import { Footer } from './footer';
import { SideNav } from './sidenav';

export default function Layout({ children }: any) {
    const [collapse, setCollapse] = useState<boolean>(true);

    return (
        <>
            <header>
                {/* {token ? <AuthenticatedNav /> : <UnauthenticatedNav />} */}
                {<UnauthenticatedNav />}
            </header>
            <main>
                <Container fluid>
                    {children}
                </Container>
            </main>
            <footer>
                <Footer />
            </footer>
        </>
    );
}

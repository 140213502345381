import Form from 'react-bootstrap/Form';
import '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, FloatingLabel, Button } from 'react-bootstrap';
import { faFacebook, faLinkedin, faGit, faJira } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faPinterest } from "@fortawesome/free-brands-svg-icons";



export function Footer() {
    return (
            <footer className="text-center text-lg-start bg-light text-muted" >

                { <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                    < div className="me-5 d-none d-lg-block" >
                        <span>Get connected with us on social networks:</span>
                    </div >
                    < div >
                        <a href="https://www.facebook.com/YTR-Infotech-362736837426879" className="me-4 text-reset">
                        <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a href="https://twitter.com/YtrInfotech" className="me-4 text-reset">
                        <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        <a href="" className="me-4 text-reset">
                        <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="" className="me-4 text-reset">
                        <FontAwesomeIcon icon={faYoutube} />
                        </a>
                        <a href="" className="me-4 text-reset">
                        <FontAwesomeIcon icon={faPinterest} />
                        </a>
                        <a href="https://www.linkedin.com/company/ytr-infotech" className="me-4 text-reset">
                        <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        <a href="https://github.com/YTRINFOTECH" className="me-4 text-reset">
                        <FontAwesomeIcon icon={faGit} />
                        </a>
                        <a href="https://ytrinfotech.atlassian.net/jira/your-work" className="me-4 text-reset">
                        <FontAwesomeIcon icon={faJira} />
                        </a>
                    </div >
                </section > }
                <br></br>
                <br></br>
                <section className="fw-italic" >
                    <div className="container text-center text-md-start mt-5">
                        <Row className="mt-3">
                            <Col xs={12} md={6} className="mx-auto mb-4">
                                
                                <h6 className="fw-bold mb-4">
                                    Get in Touch
                                </h6>
                                <h6>
                                We are professional IT experts who deal in all kind of IT outsourcing and Software Development.
                                </h6>
                                <hr className="separator-line  align_center"></hr>
                                <h4 className="vc_custom_heading align-left heading-primary"><strong>USA</strong></h4>
                                <p>3410 Bohmer Dr, Bloomington, IL, 61704</p>
                                <i className="fas fa-map-marker-alt"></i> <p>Main Line: +1 (612) 998 0375</p>
                                <p>Email: hr@ytrinfotech.com</p>
                                <hr className="separator-line  align_center"></hr>
                                <h4 className="vc_custom_heading align-left heading-primary"><strong>INDIA</strong></h4>
                                <p>Plot No: 711 & 712, Sai Anurag Colony, Bachupally, Hyderabad, 500090</p>
                                <p>Main Line: +91 (733) 007 2848</p>
                            </Col>
                            <Col xs={12} md={6} className="mx-auto mb-4">
                                <h6 className="fw-bold mb-4">
                                    Get Quick Advice
                                </h6>
                                <Form>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Form.Control type="text" placeholder="Name" />
                                            <br />
                                            <Form.Control type="email" placeholder="Email" />
                                            <br />
                                            <Form.Control type="text" placeholder="Mobile Number" />
                                            <br />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatingLabel controlId="floatingTextarea2" label="Message">
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Leave a message here"
                                                    style={{ height: '162px' }}/>
                                            </FloatingLabel>
                                        </Col>
                                        <Row>
                                            <Col className="mx-auto p-3 text-center">
                                                <Button variant="dark" size="lg">Send Message</Button>
                                            </Col>
                                        </Row>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <div className="row mt-3">
                             <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    <i className="fas fa-gem me-3"></i><b>YTR Infotech</b>
                                </h6>
                                <p>
                                YTR Infotech Corporation is the fastest growing IT services company. Its incorporated in 2006.
                                Our Headquarter is located at Bloomington, Illinois, United States of America. 
                                YTR Infotech aims to be the most preferred Business Partner of all Technology Providers and IT companies globally.
                                </p>
                            </div>
                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Industries
                                </h6>
                                <p>
                                    <a href="#!" className="text-reset">Banking</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Insurance</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Retail</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Telecomm</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Health Care</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Auto Mobile</a>
                                </p>                               
                            </div> 
                             <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Our Services
                                </h6>
                                <p>
                                    <a href="#!" className="text-reset">Staffing Solutions</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Software Services</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Customer Services</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">HR & Payroll Solutions</a>
                                </p>
                            </div> 
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Terms
                                </h6>
                                <p>
                                    <a href="#!" className="text-reset">Privacy Policy</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Disclaimer</a>
                                </p>
                            </div> 
                        </div>
                    </div>
                </section>
                <div className="text-center p-4" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                    © 2021 Copyright: YTR Infotech
                </div>
            </footer>
        
    )
}